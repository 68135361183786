@font-face {
    font-family: 'gotham';
    src: url("../fonts/gotham/Gotham-Book_0.otf"); }

@font-face {
    font-family: 'gotham';
    src: url("../fonts/gotham/Gotham-Black_0.otf");
    font-weight: bold; }

@font-face {
    font-family: 'gotham';
    src: url("../fonts/gotham/Gotham-BoldIta_0.otf");
    font-weight: lighter;
    font-style: italic; }

@media only screen and (min-width: 768px) {
    .main-content {
        padding: 20px 20px;
    }
}

body {
    background: #fafafa;
}

h1 {
    font-size: 22px;
}

nav.navbar {
    background: #37205b;
    border: none;
    border-radius: 0px;
    /*border-top: none;*/
    /*border-left: none;*/
    /*border-right: none;*/
    /*border-bottom: 1px solid #ddd;*/
}

.navbar-brand {
    background: url('../images/partybookers_logo6.png') no-repeat;
    background-size: contain;
    /*min-height: 80px;*/
    min-width: 400px;
    text-indent: -9999px;
}

.navbar-default .navbar-nav>li>a {
    color: #cfcfcf;
}

.navbar-default .navbar-nav>li>a:hover {
    color: #ffffff;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    background-color: #ffffff;
    color: #333;
}

.navbar-toggle {
    padding: 20px 20px;
    margin-top: 25px;
}

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.tab-content {
    background: #ffffff;
}

.datepicker table tr td.old, .datepicker table tr td.new {
    color: #5F5F5F;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
    color: #E2E2E2;
    cursor: not-allowed;
}

.datepicker table thead tr:first-child {
    background: #333;
    color: #ffffff;
}

.datepicker table thead:first-child tr th {
    border-radius: 0px;
}

.datepicker thead tr:first-child th:hover {
    background: #000000;
}

.datepicker table tr td.today,
    .datepicker table tr td.day:hover,
    .datepicker table tr td.active.active,
    .datepicker table tr td.today:hover,
    .datepicker table tr td.today:hover:hover{
    background: #2AA37C;
    color: #ffffff;
}

/*
.btn-success {
    background-color: #2AA37C;
    border-color: #2AA37C;
}*/

aside {
    margin-top: 0px;
    max-width: 250px;
    /*border-bottom: 1px solid #c4c4c4;*/
    float: left;
}

.container-narrow {
    width: 95%;
    margin-left: 15px;
    float: left;
}

.btn-xs {
    font-size: 10px;
}

table.table>tbody>tr:hover {
    background: #f1f1f1;
}

@media (max-width: 768px) {
    aside {
        display: none;
    }

    ul.top-navigation-links img {
        display: none;
    }

    li.nav-heading {
        font-family: 'gotham';
        color: #fff;
        font-size: 16px;
        padding-left: 5px;
    }
}

@media (min-width: 769px) {
    ul.top-navigation-links {
        display: none;
    }
    .container-narrow {
        width: calc(100% - 275px);
    }
    nav.navbar {
        display: none;
    }
}

.table>thead:first-child>tr:first-child>th {
    border-top: 1px solid #eee;
}

.pagination {
    margin: 0 0 5px 0;
}

.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    border: 1px solid #DBD9D9;
    min-height: 35px;
    height: auto;
    color: #89949b;
}

.dailyreport-data {
    margin-left: 25px;
}

.sidebar .nav > li > a, .sidebar .nav > li > .nav-item {
    padding: 12px 12px;
    color: #3d045b;
    font-family: 'gotham';
}

.search-ticket-retailer {
    width: 200px !important;
}

.search-repeating-event {
    width: 200px !important;
}

#ticket-status {
    width: 120px;
}

.top .dataTables_info {
    float: left;
    padding-right: 25px;
}

.bottom .dataTables_info {
    float: left;
}

a.dt-button {
    color: #333;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid;
    border-color: #ccc;
    border-radius: 3px;
    font-size: 13px;
    transition: all 0.1s;
}

a.dt-button:hover {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border: 1px solid #ccc !important;
    border-radius: 3px;
    font-size: 13px;
    transition: all 0.1s;
}

.sidebar {
    /*background: #2e3b42;*/
    background: #fafafa;
    border-right: none;
    border-bottom: none;
    /*border-right: 1px solid #050505;*/
    /*background: rgb(55,32,91);*/
    /*background: linear-gradient(180deg, rgba(55,32,91,1) 0%, rgba(9,9,121,1) 20%, rgba(46,59,66,1) 100%);*/
}

.sidebar .nav-heading {
    color: #9c01a8;
    font-family: 'gotham';
    font-weight: bold;
}

.sidebar .nav > li.active, .sidebar .nav > li:hover, .sidebar .nav > li.active > a, .sidebar .nav > li:hover > a, .sidebar .nav > li.active > a > .item-text, .sidebar .nav > li:hover > a > .item-text, .sidebar .nav > li.active .nav, .sidebar .nav > li:hover .nav {
    /*background-color: #2AA37C;*/
    /*background-color: #280735;*/
    /*background-color: #ffed00;*/
    /*background: rgb(255,237,0);*/
    /*background: linear-gradient(90deg, rgba(255,237,0,1) 80%, rgba(250,250,250,1) 100%);*/
    background: #eee;
    border-radius: 0px 50px 50px 0px;
}

.sidebar .nav > li:hover > a > .item-text {
    /*color: #ffffff;*/
}

.sidebar .nav > li.active > a > em, .sidebar .nav > li:hover > a > em {
    color: #089999;
    /*color: #33cc99;*/
    /*color: #ffffff;*/
}

#weeklyEventsTable td {
    text-align: center;
}

#weeklyEventsTable thead th:not(:first-child) {
    text-align: center
}

#ticket-table-container {
    width: 100%;
}

#event-info-page p {
    margin-top: 8px;
}

label {
    font-weight: 600;
}

.datetimepicker {
    width: 170px;
}

.table > tbody > tr.selected > td {
    background: rgba(204,255,204,0.5);
}

#ticket-table-container .checkbox-inline {
    padding: 0;
    display: block;
}

.consolidationCheckbox {
    width: 50px;
    height: 25px;
    z-index: 5;
}

#feedbackButtons input {
    margin-left: 5px;
}

.small-input {
    width: 100px !important;
}

#extra_items_container select {
    width: 50px;
    float: left;
    padding: 5px 5px;
}

#extra_items_container label {
    font-size: 14px;
    margin-left: 10px;
    padding-top: 7px;
}

#map {
    height: 400px;
}

#map2 {
    height: 500px;
}

#upcomingEventsTable {
    table-layout: fixed;
}

#upcomingEventsTable tr {
    height: 50px;
}

.key-cell {
    width: 75px;
    float: left;
}

.key-cell .progress-bar {
    height: 8px;
}

.banner img {
    position: relative;
    max-width: 100%;
}

.banner h2 {
    position: absolute;
    font-size: 48px;
    color: #ffffff;
    margin-top: -72px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 1050px;
    background: rgba(0,0,0,0.65);
}

#map-page {
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

#map-page #map {
    height: 100vh;
}

#map-sidebar {
    position: absolute;
    top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(255,255,255,0.85);
    box-shadow: 4px 6px 17px 2px rgba(0,0,0,0.1);
    z-index: 99;
}

#map-sidebar h1 {
    font-size: 14px;
}

@media only screen and (max-width: 768px) {
    #map-sidebar {
        position: static;
    }
    #map-sidebar h1 {
        font-size: 12px;
    }

    #map-sidebar hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #map-sidebar .navbar-brand {
        width: 100%;
    }
}

#map .gm-style-iw h4 {
    margin-top: 0;
}

#map .gm-style-iw img {
    width: 115px;
    margin-right: 15px;
}

/** Begin Loader CSS */
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(126,78,151, 0.2);
    border-right: 1.1em solid rgba(126,78,151, 0.2);
    border-bottom: 1.1em solid rgba(126,78,151, 0.2);
    border-left: 1.1em solid #7e4e97;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/** End Loader CSS */

#upcomingEventsContainer .loader {
    position: absolute;
    top: 170px;
    left: 47%;
}

#upcoming h3 {
    margin-top: 0px;
}

#flyer-table tr:not(.flyer-group) td:nth-child(3), #flyer-table2 tr:not(.flyer-group) td:nth-child(3) {
    color: #fff;
}

.btn-success {
    background-color: #089999;
}

.btn-danger {
    background-color: #ff00f9;
}

.text-danger {
    color: #ff00f9;
}